import { AccountInfo } from "@azure/msal-browser";
import { createContext, useContext } from "react";
import { Me_me } from "./../gql/types/Me";

export const LeoAuthContext = createContext(undefined);

type UseAuth = {
  auth: {
    error: string;
    account: AccountInfo;
    me: Me_me;
    token: string | undefined;
  };
  signOut: () => void;
  signIn: () => void;
};

/**
 * Use Auth hook
 * Returns current auth state and signIn / signOut functions
 *
 * @returns  [{account: AccountInfo, error: string, token: string}, signIn, signOut]
 */
export const useAuth = (): UseAuth =>
  useContext<UseAuth>(LeoAuthContext as any);
