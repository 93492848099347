import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  TypePolicies,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import {
  aquireBeApiToken_Silent,
  getCSRFToken,
} from "LEOTheme/utils/auth-utils";
import React from "react";

/**
 * Init apollo client
 */

const withToken = setContext(async () => {
  // CSRF token
  const csrfToken = await getCSRFToken();

  // get test or real token
  const accessToken = await (await aquireBeApiToken_Silent()).accessToken;

  return {
    headers: {
      "X-CSRFToken": csrfToken,
      authorization: accessToken ? `Bearer ${accessToken}` : "",
    },
  };
});

export const createApolloClient = (options?: LEOApolloProviderProps) => {
  const httpLink = ApolloLink.split(
    (operation) => operation.getContext().hasUpload,
    createUploadLink({
      uri: process.env.REACT_APP_API_ENDPOINT,
      credentials: "include",
    }),
    new HttpLink({
      uri: process.env.REACT_APP_API_ENDPOINT,
      credentials: "include",
    })
  );

  return new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        ...(options ? options.typePolicies : {}),
      },
    }),
    link: ApolloLink.from([withToken, httpLink]),
  });
};

interface LEOApolloProviderProps {
  children: React.ReactNode;
  typePolicies: TypePolicies;
}

/**
 * Wrapper for ApolloProvider that initiates client and connects to GraphQL API with authentication.
 *
 * @param props
 */
export const LEOApolloProvider = (props: LEOApolloProviderProps) => {
  const client = createApolloClient(props);
  return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
};
