// import 'react-app-polyfill/ie11';
// import 'react-app-polyfill/stable';
import { leoThemeConfig } from "leo-theme-config";
import React from "react";
import ReactDOM from "react-dom";
import { LEOPreAuthAppWrapper } from "./LEOTheme/components/LEOPreAuthAppWrapper";
import "./LEOTheme/stylesheet/leo-theme.css";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
  <LEOPreAuthAppWrapper appMetadata={leoThemeConfig.appMetaData} />,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
