import { LEOAuthProvider } from "LEOTheme/components/LEOAuthProvider";
import { LEOStatusCheckProvider } from "LEOTheme/components/LEOStatusCheckProvider";
import { LEOThemeProvider } from "LEOTheme/components/LEOThemeProvider";
import { LEOGlobalStateProvider } from "LEOTheme/utils/leo-global-state";
import { AppMetadata } from "LEOTheme/utils/type-utils";
import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { LEODatadogProvider } from "./LEODatadogProvider";
import { LEOToastProvider } from "./LEOToastProvider";

interface LEOPreAuthAppWrapperProps extends PropsWithChildren<any> {
  appMetadata: AppMetadata;
  disableSasValidation?: boolean;

  /** custom no access page */
  customNoAccessPage?: React.ReactElement;
}

export const LEOPreAuthAppWrapper = (props: LEOPreAuthAppWrapperProps) => {
  const app = useRef(null);
  const [appLoaded, setAppLoaded] = useState(false);

  /**
   * Utils
   */

  const loadApp = async () => {
    import("../../components/App").then(({ App }) => {
      app.current = App;
      setAppLoaded(true);
    });
  };

  // check if sasToken validation is disabled (used for local dev)
  useEffect(
    () => {
      // if disabled > auto load app
      if (props.disableSasValidation) {
        loadApp();
      }
    },
    // eslint-disable-next-line
    []
  );

  /**
   * Render
   */

  return (
    <Router>
      <LEOThemeProvider>
        <LEOStatusCheckProvider appMetadata={props.appMetadata}>
          <LEOToastProvider>
            <LEOGlobalStateProvider>
              <LEODatadogProvider>
                <LEOAuthProvider
                  customNoAccessPage={props.customNoAccessPage}
                  onSASTokenFetched={
                    props.disableSasValidation ? undefined : () => loadApp()
                  }
                  appMetadata={props.appMetadata}
                >
                  {appLoaded && <app.current />}
                </LEOAuthProvider>
              </LEODatadogProvider>
            </LEOGlobalStateProvider>
          </LEOToastProvider>
        </LEOStatusCheckProvider>
      </LEOThemeProvider>
    </Router>
  );
};
