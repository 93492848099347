import { LEOThemeConfig } from "LEOTheme/utils/type-utils";

export const leoThemeConfig: LEOThemeConfig = {
  maskDatadogActions: true,
  disableProfilePictures: false,
  disableGQLFiles: [
    "ALL_USEROBJECTPERMISSION_SLIM",
    "ALL_USEROBJECTPERMISSION",
  ],
  appMetaData: {
    appTitle: "Boilerplate Reference App",
    contactName: "Sebastian Balle",
    contactEmail: "qbedk@leo-pharma.com",
    byline: "Developed by Data Science & AI",
  },
};
