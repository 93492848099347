import { Button, ButtonProps, makeStyles, Tooltip } from "@material-ui/core";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { LEOColor, LEOColorSet, leoColorSets } from "LEOTheme/LEOColors";
import { leoGetBorderString } from "LEOTheme/LEOTheme";
import { ensure } from "LEOTheme/utils/type-utils";
import React from "react";

const useStyles = makeStyles(() => ({
  leoButton: (s: LEOColorSet) => ({
    backgroundColor: s.backgroundColor,
    border: leoGetBorderString(s.backgroundColor),
    color: s.color,
    "&:hover": {
      backgroundColor: s.hoverBackgroundColor,
    },
    "&.Mui-disabled.MuiButton-contained": {
      backgroundColor: s.backgroundColor,
      color: fade(s.color, 0.35),
    },
  }),
}));

export interface LEOButtonProps extends ButtonProps {
  /** LEOColor name. This is used to find the corresponding color set as defined in LEOColors */
  leoColor?: LEOColor;

  /** disabled tooltip */
  disabledTooltip?: string;
}

/**
 * Wrapper for MUI Button component.
 * Enforces 'contained' variant and requires a LEO color set.
 *
 * https://material-ui.com/components/buttons/
 *
 * @param props LEOButtonProps
 */

export const LEOButton = (props: LEOButtonProps) => {
  const leoColorSet = ensure(
    leoColorSets.find((s) => s.leoColor === (props.leoColor || "lightGreen")),
    `LEOColor ${props.leoColor} not found in Colors`
  );

  // create class containing leo color css props
  const classes = useStyles(leoColorSet);

  // remove unsupported props before passing to MUI Button
  const passedProps = { ...props };
  delete passedProps.leoColor;
  delete passedProps.disabledTooltip;

  // button element
  const buttonElement = (
    <Button
      className={classes.leoButton}
      variant={"contained"}
      {...passedProps}
    >
      {props.children}
    </Button>
  );

  if (props.disabledTooltip && props.disabled) {
    return (
      <Tooltip title={props.disabledTooltip}>
        <span>{buttonElement}</span>
      </Tooltip>
    );
  } else {
    return buttonElement;
  }
};
