import React from "react";
import { leoColorSets } from "../LEOColors";
import {
  CardContent,
  Typography,
  Card,
  Snackbar,
  Divider,
  SvgIcon,
} from "@material-ui/core";
import { leoLayoutContainerPadding } from "../LEOTheme";
import Slide, { SlideProps } from "@material-ui/core/Slide";
import { ToastState } from "../utils/toast-utils";
import LEOSecondaryButton from "./LEOSecondaryButton";

interface LEOToastProps extends ToastState {
  /** Set open to 'true' to open the toast */
  open: boolean;
  /** Animate in and out duration in miliseconds */
  transitionDuration?: number;
}

type TransitionProps = Omit<SlideProps, "direction">;

const GetTransition = (props: TransitionProps) => {
  return <Slide {...props} direction="left" />;
};

/**
 * Shows a Toast in the top right corner.
 *
 * @param props LEOToastProps
 */
export const LEOToast = (props: LEOToastProps) => {
  const { milliseconds = 7000, transitionDuration = 400 } = props;
  /**
   * Effects and utils
   */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    props.onClose();
  };

  /**
   * Render
   */
  return (
    <Snackbar
      TransitionComponent={GetTransition}
      transitionDuration={transitionDuration}
      open={props.open}
      autoHideDuration={props.confirmButtonText ? null : milliseconds}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      onClose={handleClose}
    >
      <Card
        style={{
          width: "500px",
          padding: "0px",
        }}
      >
        <CardContent
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            padding: "0px",
            height: "100%",
          }}
        >
          <div
            style={{
              minHeight: "50px",
              display: "flex",
              flex: 1,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SvgIcon
              htmlColor={
                leoColorSets.find((lc) => lc.leoColor === props.color)
                  ?.backgroundColor
              }
            >
              {props.icon}
            </SvgIcon>
          </div>
          <Divider
            variant={"fullWidth"}
            orientation="vertical"
            flexItem
            style={{
              width: "5px",
              backgroundColor: leoColorSets.find(
                (lc) => lc.leoColor === props.color
              )?.backgroundColor,
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 9,
              padding: leoLayoutContainerPadding / 2,
            }}
          >
            <Typography style={{ whiteSpace: "pre-line" }} variant={"body1"}>
              {props.text}{" "}
            </Typography>
            {props.confirmButtonText && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "end",
                  width: "100%",
                  paddingTop: leoLayoutContainerPadding / 2,
                }}
              >
                <LEOSecondaryButton
                  onClick={(event) => handleClose(event, "confirmClick")}
                >
                  {props.confirmButtonText}
                </LEOSecondaryButton>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </Snackbar>
  );
};
