import { datadogRum } from "@datadog/browser-rum";
import { staticToastAPI } from "../components/LEOToastProvider";

/** Ensures mutation has .ok which can be checked, and is returned as boolean */
export const mutationSucceeded = (mutationData: { ok: boolean }) =>
  mutationData.ok;

/**
 * Utility to log graphql errors - errors in console, tracks and show toast
 *
 * Usage:
 * ```
 * if(mutationSucceeded(createTrainedmodelResponse.data.createTrainedmodel)){
 *   ...
 * }
 * else{
 *   logGraphqlErrors(createTrainedmodelResponse.data.createTrainedmodel.errors);
 * }
 * ```
 *
 * @param errors - errors from graphql mutation
 * @param track - default value is true
 * @param showToast - default value is true
 */
export const logGraphqlErrors = (
  errors: { field?: string; messages: string[] }[],
  prependMessage?: string,
  track = true,
  showToast = true
) => {
  console.error("One or more graphql errors happened: ", errors);
  try {
    errors.forEach((e) => {
      if (track) {
        trackError(
          `A GraphQL error has happened on field: ${e.field}, error messages: ${e.messages}`
        );
      }
      if (showToast) {
        const message = `A GraphQL error has happened on field: ${
          e.field
        }, error messages: ${e.messages.join(". ")}`;
        showErrorToast(
          prependMessage ? `${prependMessage}: ${message}` : message
        );
      }
    });
  } catch (e) {
    console.error("Error handling failed - logGraphqlErrors()", e);
  }
};

/**
 * Log JavaScript and network errors
 *
 * @param e
 * @param track
 * @param showToast
 */
export const logError = (
  e: Error,
  prependMessage?: string,
  track = true,
  showToast = true
) => {
  console.error("An error occured: ", e);
  try {
    if (track) {
      trackError(
        `An error occured, name: ${e.name}, message: ${e.message}, stack: ${e.stack}`
      );
    }
    if (showToast) {
      const message = e.message.replace("GraphQL error:", "");
      showErrorToast(
        prependMessage
          ? `${prependMessage}. \n ${message}`
          : `An error occured. \n ${message}`
      );
    }
  } catch (e) {
    console.error("Error handling failed - logError()", e);
  }
};

/** Internal utility for tracking errors */
const trackError = (message: string) => {
  if (process.env.REACT_APP_DATADOG_ENABLED) { 
    datadogRum.addError(new Error(message));
  }
};

/** Internal utility for showing errors as toast to the users */
const showErrorToast = (text: string) => {
  staticToastAPI.showError({
    text: text,
    confirmButtonText: "Ok",
  });
};
