import { leoTheme } from "LEOTheme/LEOTheme";
import { ThemeProvider } from "@material-ui/core";
import React from "react";

interface LEOThemeProviderProps {
  // Children to add inside provider
  children: React.ReactNode;
}

/**
 * Wrapper for ThemeProvider that sets the leoTheme as default
 *
 * @param props
 */
export const LEOThemeProvider = (props: LEOThemeProviderProps) => (
  <ThemeProvider theme={leoTheme}>{props.children}</ThemeProvider>
);
