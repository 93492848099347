import { MessageStatus } from "LEOTheme/components/LEOStatusCheckProvider";
import { createContext, useContext } from "react";

export const LEOStatusMessageContext = createContext(undefined);

/**
 * Hook for getting status message defined in Dashboard / projects.json
 *
 * @returns {type: "no-message" | "info" | "warning", message?: string;}
 */
export const useMessageStatus = (): MessageStatus =>
  useContext<MessageStatus>(LEOStatusMessageContext);
