import {
  AccountInfo,
  AuthenticationResult,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { datadogRum } from "@datadog/browser-rum";
import { Theme, useMediaQuery } from "@material-ui/core";
import { ME } from "LEOTheme/gql/ME";
import { SAS_TOKEN } from "LEOTheme/gql/SAS_TOKEN";
import { Me_me } from "LEOTheme/gql/types/Me";
import {
  aquireBeApiToken_Redirect,
  aquireBeApiToken_Silent,
  aquireMicrosoftGraphToken_Redirect,
  aquireMicrosoftGraphToken_Silent,
  msalInstance,
} from "LEOTheme/utils/auth-utils";
import { logError } from "LEOTheme/utils/error-utils";
import { AppMetadata } from "LEOTheme/utils/type-utils";
import { LeoAuthContext } from "LEOTheme/utils/use-auth";
import { leoThemeConfig } from "leo-theme-config";
import React, { useEffect, useReducer, useState } from "react";
import { leoColorWhite } from "../LEOColors";
import { LEOAbout } from "./LEOAbout";
import { createApolloClient } from "./LEOApolloProvider";
import { LEONoAccessMessage } from "./LEONoAccessMessage";
import { LEOServiceDownMessage } from "./LEOServiceDownMessage";

export interface LEOAuthProviderProps {
  /** children to render when the authentication is successfull */
  children: React.ReactNode;

  /** Props for LEOAppIdent - used for displaying loader */
  appMetadata: AppMetadata;

  /** Only login on FE - skip query for user on backend */
  feOnlyAuth?: Boolean;

  /** on sasToken fetched */
  onSASTokenFetched?: () => void;

  /** custom no access page */
  customNoAccessPage?: React.ReactElement;
}

export interface AuthProviderState {
  /** Account returnd when autorization is successfull */
  account?: AccountInfo;
  /** Me django user*/
  me?: any;
  /** Error returned from MSAL library if login fails */
  error?: string | "service-unavailable" | "no-access";
}

const initialState: AuthProviderState = {};

/**
 * LEO Authorization Provider using React Context API.
 *
 * Will handle login redirect flow and enable use for utility hook: useAu ())
 *
 * Adapted from official microsoft sample (30/7/2020):
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/samples/msal-core-samples/react-sample-app/src/AuthProvider.js
 *
 */
export const LEOAuthProvider = (props: LEOAuthProviderProps) => {
  const [
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    error,
    setError,
  ] = useState();

  // sa login request
  const [showSALogin, setShowSALogin] = useState(false);

  /**
   * Context state
   */
  const [state, dispatch] = useReducer((state, action) => {
    return { ...state, ...action };
  }, initialState);

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  const getMe = () => {
    const client = createApolloClient();
    client
      .query({
        query: ME,
      })
      .then((res) => {
        dispatch({
          me: res.data.me,
        });
      })
      .catch((e) => {
        switch (e.networkError.statusCode) {
          case 500:
            dispatch({
              error: "service-unavailable",
            });
            break;
          default:
            dispatch({
              error: "no-access",
            });
            break;
        }
      });
  };

  /**
   * Fetch a SAS token to be used for loading project specific js chunks
   */

  const fetchSASToken = async () => {
    try {
      const client = createApolloClient();
      const response = await client.query({
        query: SAS_TOKEN,
      });
      if (response.data.sasToken) {
        (window as any)["__sasToken"] = response.data.sasToken;
        props.onSASTokenFetched();
      } else {
        dispatch({
          error: "no-access",
        });
      }
    } catch (error) {
      dispatch({
        error: "no-access",
      });
    }
  };

  /**
   * Effects and utils
   */
  useEffect(
    () => {
      const login = async () => {
        //Flow explanation: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md#acquiring-an-access-token
        let handledSucessfully = true;
        let tokenResponse: AuthenticationResult = await msalInstance
          .handleRedirectPromise()
          .catch(async (error) => {
            if (
              error instanceof InteractionRequiredAuthError &&
              error.message.match(
                /.*The signed in user '{EmailHidden}' is not assigned to a role for the application.*/
              )
            ) {
              // Show 'Access restricted'-page
              console.error(
                "Error in LEOAuthProvider.msalInstance.handleRedirectPromise - user does not have access:\n\n" +
                  error.name +
                  ": " +
                  error.message
              );
              dispatch({
                error:
                  "Error in LEOAuthProvider.msalInstance.handleRedirectPromise - user does not have access:\n\n" +
                  error.name +
                  ": " +
                  error.message,
              });
            } else {
              // Bubble error and show uncaught error page
              setError(() => {
                throw new Error(
                  "Error occured in LEOAuthProvider.msalInstance.handleRedirectPromise: " +
                    error.message
                );
              });
            }
            handledSucessfully = false;
            return null;
          });
        if (tokenResponse === null && handledSucessfully) {
          // If account exists, it means user has been logged in before.
          tokenResponse = await aquireBeApiToken_Silent().catch(
            async (error) => {
              if (error instanceof InteractionRequiredAuthError) {
                //Scope needs to be escalated, prompt user permission
                console.info(
                  "Could not aquire BE token silently, redirecting..."
                );
                await aquireBeApiToken_Redirect();
              } else {
                logError(error);
                console.error(
                  "Failed to aquire BE API token silently: " + error.message
                );
                setError(() => {
                  throw new Error(
                    "Error occured while trying to aquire BE API token silently: " +
                      error.message
                  );
                });
              }
              return null;
            }
          );
        }
        // Microsoft Graph Flow:
        if (
          leoThemeConfig.disableProfilePictures === false &&
          process.env.REACT_APP_MICROSOFT_GRAPH_API_SCOPE &&
          handledSucessfully
        ) {
          await aquireMicrosoftGraphToken_Silent().catch(async (error) => {
            if (error instanceof InteractionRequiredAuthError) {
              //Scope needs to be escalated, prompt user permission
              console.info(
                "Could not aquire Microsoft Graph token silently, redirecting..."
              );
              await aquireMicrosoftGraphToken_Redirect();
            } else {
              logError(error);
              console.error(
                "Failed to aquire Microsoft graph token silently: " +
                  error.message
              );
              setError(() => {
                throw new Error(
                  "Error occured while trying to aquire Microsoft Graph token silently: " +
                    error.message
                );
              });
            }
            return null;
          });
        }

        // if callback set - fetch SAS token - a short lived access token used to auth subsequent asset js chunk loads
        if (props.onSASTokenFetched) {
          await fetchSASToken();
        }

        //Done with auth flows, dispatch account to open app
        dispatch({
          account: tokenResponse.account,
          token: tokenResponse.accessToken,
        });

        // Set Datadog user
        if (process.env.REACT_APP_DATADOG_ENABLED && tokenResponse?.account) {
          datadogRum.setUser({
            id: tokenResponse.account.username,
            name: tokenResponse.account.name,
            email: tokenResponse.account.username,
          });
        }

        // Get profile specific data - used when there is no backend to provide Django user
        if (props.feOnlyAuth) {
          // used when no backend user avalible
          const me: Me_me = {
            isSuperuser: false,
            isStaff: false,
            __typename: "Me",
            dateJoined: "",
            id: tokenResponse.account.username,
            isActive: true,
            lastLogin: "",
            firstName: tokenResponse.account.name.split(" ")[0],
            lastName: tokenResponse.account.name
              .split(" ")
              .filter((s, i) => i > 0)
              .join(" "),
            username: tokenResponse.account.username,
            email: tokenResponse.account.username,
          };
          dispatch({ me });
        } else {
          // fetch user from backend
          getMe();
        }
      };

      login();
    },
    // eslint-disable-next-line
    []
  );

  /**
   * Signin the user using Azure AD
   *
   * @param redirect - redirect to this page
   */
  const signIn = async () => {
    await msalInstance.loginRedirect();
  };

  /**
   * Sign out the user from Azure AD
   */
  const signOut = async () => {
    await msalInstance.logout();
  };

  /**
   * render
   */

  // default render
  return (
    <LeoAuthContext.Provider value={{ auth: state, signIn, signOut } as any}>
      {state.account && state.me && !state.error ? (
        props.children
      ) : (
        <div
          style={{
            display: "flex",
            position: "fixed",
            height: "100%",
            width: "100%",
            justifyContent: !isMobile && "center",
            alignItems: !isMobile && "center",
            backgroundColor: isMobile && leoColorWhite,
          }}
        >
          {/** Service down error */}
          {state.error && state.error === "service-unavailable" && (
            <LEOAbout
              alternateContent={
                <LEOServiceDownMessage appMetadata={props.appMetadata} />
              }
              useCase={"noAccess"}
              appMetadata={props.appMetadata}
            />
          )}
          {/** No access errror */}
          {state.error && state.error !== "service-unavailable" && (
            <>
              {props.customNoAccessPage || (
                <LEOAbout
                  alternateContent={
                    <LEONoAccessMessage appMetadata={props.appMetadata} />
                  }
                  useCase={"noAccess"}
                  appMetadata={props.appMetadata}
                />
              )}
            </>
          )}
        </div>
      )}
    </LeoAuthContext.Provider>
  );
};
