import React from "react";
import { LEOButton, LEOButtonProps } from "./LEOButton";

/**
 * Wrapper for LEOButton with default setting as Primary
 *
 * @param props LEOButtonProps
 */
const LEOPrimaryButton = (props: LEOButtonProps) => {
  return (
    <LEOButton
      leoColor={"lightGreen"}
      variant={"contained"}
      color={"primary"}
      {...props}
    >
      {props.children}
    </LEOButton>
  );
};

export default LEOPrimaryButton;
