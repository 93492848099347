import React, { useEffect, useRef, useState } from "react";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import WarningIcon from "@material-ui/icons/Warning";
import { LEOToast } from "./LEOToast";
import {
  LeoToastContext,
  ReducedToastState,
  ToastState,
  UseToastAPI,
} from "LEOTheme/utils/toast-utils";

export interface LEOToastProviderProps {
  children: React.ReactElement;
}

export let staticToastAPI: UseToastAPI = undefined;

export const LEOToastProvider = (props: LEOToastProviderProps) => {
  const [show, setShow] = useState(false);
  const transitionDuration = 400;
  const [toastState, setToastState] = useState<ToastState | undefined>(
    undefined
  );
  const queue = useRef<ToastState[]>([]);

  const addToQueue = (toast: ToastState) => {
    queue.current.push(toast);
    if (!show) {
      //Show Toast
      setToastState(queue.current[0]);
      setShow(true);
    }
  };

  const showToast = (toast: ToastState) => {
    addToQueue(toast);
  };

  const showError = (toast: ReducedToastState) => {
    addToQueue({
      color: "error",
      icon: <ErrorOutlineIcon />,
      ...toast,
    });
  };

  const showWarning = (toast: ReducedToastState) => {
    addToQueue({
      color: "sunflower",
      icon: <WarningIcon />,
      ...toast,
    });
  };

  const onClose = () => {
    if (toastState.onClose) {
      toastState.onClose();
    }
    //Close old Toast
    setShow(false);
    // Wait for animation
    setTimeout(() => {
      //Check if new Toast in queue
      if (queue.current.length > 0) {
        //Delete the old
        queue.current.shift();
        //Show Toast
        setToastState(queue.current[0]);
        setShow(true);
      }
    }, transitionDuration);
  };

  useEffect(
    () => {
      staticToastAPI = { showToast, showError, showWarning };
    },
    // eslint-disable-next-line
    []
  );

  return (
    <>
      {toastState && (
        <LEOToast
          transitionDuration={transitionDuration}
          icon={toastState.icon}
          open={show}
          onClose={onClose}
          milliseconds={toastState.milliseconds}
          color={toastState.color}
          text={toastState.text}
          confirmButtonText={toastState.confirmButtonText}
        />
      )}
      <LeoToastContext.Provider value={{ showToast, showError, showWarning }}>
        {props.children}
      </LeoToastContext.Provider>
    </>
  );
};
