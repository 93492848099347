import React, { createContext, useContext } from "react";
import { LEOColor } from "../LEOColors";

export const LeoToastContext = createContext(undefined);

export interface ToastState {
  /** The text displayed in the toast */
  text: string;
  /** The color of icon and devider as string */
  color: LEOColor;
  /** The MUI icon to be shown */
  icon: React.ReactElement;
  /** Optional: Miliseconds before closing */
  milliseconds?: number;
  /** Optional: Text on confirm button that closes toast - if not specified, button will not show */
  confirmButtonText?: string;
  /** Callback called right before closing the toast */
  onClose?: () => void;
}

/** Contains options from ToastState, however color and icon is already specified for you */
export interface ReducedToastState extends Omit<ToastState, "color" | "icon"> {}

export interface UseToastAPI {
  showToast: (toast: ToastState) => void;
  showError: (toast: ReducedToastState) => void;
  showWarning: (toast: ReducedToastState) => void;
}

export const useToast = (): UseToastAPI =>
  useContext<UseToastAPI>(LeoToastContext);
