import { Theme, useMediaQuery } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import {
  leoLayoutContainerPadding,
  leoLayoutContainerPaddingMobile,
  leoSpacing,
} from "LEOTheme/LEOTheme";
import React from "react";

interface LEOPaddedContainerProps {
  /** Content of the container */
  children: React.ReactNode;

  /** Style */
  style?: CSSProperties;

  /** Classname  */
  className?: string;

  /** No top padding */
  noTopPadding?: boolean;

  /** No top padding */
  noBottomPadding?: boolean;
}

/**
 * A container with basic padding
 *
 * @param props LEOPaddedContainer
 */
const LEOPaddedContainer = (props: LEOPaddedContainerProps) => {
  const { children, style, className } = props;
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  return (
    <div
      style={{
        padding: isMobile
          ? leoLayoutContainerPaddingMobile
          : leoLayoutContainerPadding,
        paddingTop: props.noTopPadding ? 0 : leoSpacing * (isMobile ? 2 : 3),
        paddingBottom: props.noBottomPadding
          ? 0
          : leoSpacing * (isMobile ? 2 : 3),
        ...style,
      }}
      className={className}
    >
      {children}
    </div>
  );
};

export default LEOPaddedContainer;
