import { makeStyles } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { getSet, LEOColor } from "LEOTheme/LEOColors";
import { leoSpacing } from "LEOTheme/LEOTheme";
import React from "react";

export interface LEOSpinnerProps {
  /** Color ('light' / 'dark') */
  color?: "light" | "dark";

  /** leocolor */
  leoColor?: LEOColor;

  /** Style CSSProperties */
  style?: CSSProperties;
}

const useStyles = makeStyles(() => ({
  dots: {
    "&.leo-spinner .dot:after": {
      backgroundColor: (props?: LEOSpinnerProps) =>
        props.leoColor ? getSet(props.leoColor).variation3 : undefined,
    },
    "&.leo-spinner .lead:after": {
      backgroundColor: (props?: LEOSpinnerProps) =>
        props.leoColor ? getSet(props.leoColor).base : undefined,
    },
  },
}));

/**
 * Spinner to show loading state
 *
 * @param param props
 */
const LEOSpinner = (props: LEOSpinnerProps) => {
  /**
   * Style
   * */

  const { color = "dark", style } = props;
  const classes = useStyles(props);

  /**
   * Render
   */
  return (
    <div
      style={{
        marginTop: -leoSpacing * 1,
        marginBottom: -leoSpacing * 1,
        ...style,
      }}
      className={
        "leo-spinner leo-spinner--" + color + (props.leoColor && classes.dots)
      }
    >
      <div className={"dot"}></div>
      <div className={"dot"}></div>
      <div className={"dot"}></div>
      <div className={"dot"}></div>
      <div className={"dot"}></div>
      <div className={"dot"}></div>
      <div className={"dot"}></div>
      <div className={"lead"}></div>
    </div>
  );
};

export default LEOSpinner;
