import {
  Card,
  CardContent,
  Link,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { AppMetadata } from "LEOTheme/utils/type-utils";
import React, { CSSProperties, ReactNode, useState } from "react";
import { leoColorDarkGreen0, leoColorWhite } from "../LEOColors";
import { leoLayoutContainerPaddingMobile, leoSpacing } from "../LEOTheme";
import { getENV } from "../utils/string-utils";
import { LEOAppIdent } from "./LEOAppIdent";
import LEOPaddedContainer from "./LEOPaddedContainer";
import LEOPrimaryButton from "./LEOPrimaryButton";
import LEOSpinner from "./LEOSpinner";

interface LEOAboutProps {
  /** Data to display */
  appMetadata: AppMetadata;

  /** Used as loader while login or on about click from top bar */
  useCase: "loading" | "about" | "noAccess" | "unhandledError";

  /** Callback for closing when used as about page */
  close?: () => void;

  /** alternate content */
  alternateContent?: React.ReactNode;
}

/**
 * Display containing information about creator of this app (Hardcoded to R&D Data & Analytics)
 *
 * Can be used and is being used at the login loading screen and as a popup about dialog
 *
 * @param props LEOAboutProps
 */
export const LEOAbout = (props: LEOAboutProps) => {
  /**
   * Local state
   */
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );
  const env = getENV();

  const [isShorthand, setIsShorthand] = useState(true);

  const buildId = (process.env.REACT_APP_BUILD_ID as string) ?? "N/A";
  const buildSha =
    (process.env.REACT_APP_BUILD_SHA as string)?.substr(
      0,
      isShorthand ? 5 : (process.env.REACT_APP_BUILD_SHA as string).length
    ) ?? "N/A";

  /**
   * Effects and utils
   */
  const getStandardFlexDisplay = (flex?: number): CSSProperties => {
    return {
      display: "flex",
      flex: flex ?? 1,
    };
  };

  const getWrapper = (children: ReactNode) => {
    return isMobile ? (
      <div style={{ width: "inherit" }}> {children} </div>
    ) : (
      <Card style={{ minWidth: "500px" }}> {children} </Card>
    );
  };

  const fragmentLEOSpinner = () => {
    return (
      <div
        style={{
          ...getStandardFlexDisplay(),
          flexDirection: "row-reverse",
        }}
      >
        <LEOSpinner color={"light"} />
      </div>
    );
  };

  /**
   * Render
   */
  return getWrapper(
    <>
      <LEOPaddedContainer
        noTopPadding
        noBottomPadding
        style={{ backgroundColor: leoColorDarkGreen0, padding: 0 }}
      >
        <CardContent
          style={{
            backgroundColor: leoColorDarkGreen0,
            ...getStandardFlexDisplay(),
            padding: leoSpacing * 5,
          }}
        >
          <div style={{ ...getStandardFlexDisplay(3) }}>
            <LEOAppIdent inverted appTitle={props.appMetadata.appTitle} />
          </div>
          {props.useCase === "loading" && !isMobile && fragmentLEOSpinner()}
        </CardContent>
        <CardContent
          style={{
            padding: leoSpacing * 5,
            paddingBottom: leoSpacing * 4,
            height: "70px",
            color: leoColorWhite,
            ...getStandardFlexDisplay(),
            alignItems: "flex-end",
          }}
        >
          <div>
            <Typography onClick={() => setIsShorthand(false)} variant={"body2"}>
              {`Version: ${
                (process.env.REACT_APP_VERSION as string) ?? "beta"
              }`}
              {` (${buildId}.${buildSha})`}
            </Typography>
          </div>
          {props.useCase === "loading" && isMobile && fragmentLEOSpinner()}
        </CardContent>
      </LEOPaddedContainer>
      {/**
       *  Render alternate content child or default
       */}
      {props.alternateContent || (
        <LEOPaddedContainer
          noTopPadding
          noBottomPadding
          style={{
            padding: 0,
          }}
        >
          <CardContent
            style={{
              ...getStandardFlexDisplay(),
              flexDirection: isMobile ? "column" : "row",
              padding: leoSpacing * 5,
              alignItems: "center",
            }}
          >
            <div
              style={{
                ...getStandardFlexDisplay(5),
                flexDirection: "column",
              }}
            >
              <Typography variant={"body1"}>
                {props.appMetadata.byline}
              </Typography>
              <Typography variant={"body1"}>
                {`Contact: ${props.appMetadata.contactName}, `}
                <Link
                  style={{ fontWeight: 900 }}
                  href={`mailto:${props.appMetadata.contactEmail}?subject=${props.appMetadata.appTitle}`}
                >
                  {props.appMetadata.contactEmail?.toLowerCase()}
                </Link>
              </Typography>
            </div>
            <div style={{ ...getStandardFlexDisplay() }}>
              {props.useCase === "about" && (
                <div
                  style={{
                    alignItems: "center",
                  }}
                >
                  <LEOPrimaryButton
                    onClick={() => props.close && props.close()}
                    style={
                      isMobile
                        ? { marginTop: leoLayoutContainerPaddingMobile }
                        : {
                            marginLeft: leoSpacing,
                          }
                    }
                  >
                    {"Close"}
                  </LEOPrimaryButton>
                </div>
              )}
            </div>
          </CardContent>
        </LEOPaddedContainer>
      )}
    </>
  );
};
