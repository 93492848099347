import { makeStyles } from "@material-ui/core";
import React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

const useStyles = makeStyles(() => ({
  navLink: (removeLinkStyle) =>
    removeLinkStyle
      ? {
          color: "inherit",
          textDecoration: "none",
          "&:active": {
            color: "inherit",
            textDecoration: "none",
          },
        }
      : {},
}));

interface LEONavLinkProps extends NavLinkProps {
  /** Remove default link styling */
  removeLinkStyle?: boolean;
}

/**
 * Utility wrapper to remove link styling from children when using in NavLink in menus
 *
 * @param props LEONavLinkProps
 */
export const LEONavLink = (props: LEONavLinkProps) => {
  const { removeLinkStyle = true } = props;
  const classes = useStyles(removeLinkStyle);
  const passedProps = { ...props };
  delete passedProps.removeLinkStyle;
  return (
    <NavLink
      {...passedProps}
      className={classes.navLink + " " + props.className}
    />
  );
};
