import { CardContent, Link, Typography } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { leoColorSunflower0 } from "LEOTheme/LEOColors";
import { leoSpacing } from "LEOTheme/LEOTheme";
import { AppMetadata } from "LEOTheme/utils/type-utils";
import React from "react";

interface LEOServiceDownMessageProps {
  appMetadata: AppMetadata;
}

/**
 * @param props LEOServiceDownMessageProps
 */
export const LEOServiceDownMessage = (props: LEOServiceDownMessageProps) => {
  /**
   * Render
   */
  return (
    <CardContent
      style={{
        padding: leoSpacing * 5,
      }}
    >
      <Typography variant="h1">
        <WarningIcon
          style={{
            fontSize: 28,
            color: leoColorSunflower0,
            position: "relative",
            top: 6,
            marginRight: leoSpacing,
          }}
        />
        {"Service unavailable"}
      </Typography>
      <p>
        {"The application " +
          props.appMetadata.appTitle +
          " does not respond at the moment."}
      </p>
      <p>
        {`If the problem persists please contact ${props.appMetadata.contactName} at `}
        <Typography variant={"body1"}>
          <Link
            style={{ fontWeight: 900 }}
            href={`mailto:${props.appMetadata.contactEmail}?subject=${props.appMetadata.appTitle}`}
          >
            {props.appMetadata.contactEmail?.toLowerCase()}
          </Link>
        </Typography>
      </p>
      <p style={{ marginTop: leoSpacing * 5 }}>{props.appMetadata.byline}</p>
    </CardContent>
  );
};
