import _ from "lodash";

export const pluralS = (word: string, count: number) => {
  if (count === 1) {
    return word;
  } else {
    return word + "s";
  }
};

export const compilePath = (segments: string[]): string => {
  return "/" + segments.join("/");
};

export const leoIdFromEmail = (email: string) => {
  if (!email) return "";
  if (email.includes("@leo-pharma.com")) {
    return `(${email.split("@leo-pharma.com")[0].toUpperCase()})`;
  } else {
    return "";
  }
};

/**
 * Resolves string to boolean - makes sure that "True" (python format) gets evaulated correctly
 * @param string value to check
 * @returns Boolean value
 */
export const stringIsTrue = (value): boolean => {
  try {
    return value.toLowerCase() === "true";
  } catch (error) {
    return false;
  }
};

export const prettyENUM = (
  value: string,
  emptyValue?: string,
  exceptions?: any
): string => {
  if (exceptions && exceptions[value]) return exceptions[value];
  if (value === emptyValue) return "";
  if (!value) return value;
  return _.capitalize(value.toLowerCase().split("_").join(" "));
};

export const capitalizeFirstCharater = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getDateString = (date: Date) => {
  return [
    prependZeroString(date.getFullYear()),
    prependZeroString(date.getMonth()),
    prependZeroString(date.getDate()),
  ].join("-");
};

export const prependZeroString = (n: number) => {
  return n < 10 ? `0${n}` : n.toString();
};

export const getQueryVariable = (variable: string) => {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
};

/**
 * Utility to clear the current search parameter in the url. Used when you want to rest the url after a deeplink.
 */
export const clearQueryVariables = () => {
  if (window.location.search) {
    window.history.pushState({}, document.title, window.location.pathname);
  }
};

/**
 * Will stringify object types. Used to make sure raw object are not passed to render which will casuse exception in React
 */
export const ensureSimpleValue = (value: string) => {
  const type = typeof value;
  switch (type) {
    case "object":
      try {
        return JSON.stringify(value);
      } catch (error) {
        return value.toString();
      }

    default:
      return value;
  }
};

export type ENVType = "local" | "dev" | "test" | "prod";
export const getENV = (): ENVType => {
  const href = window.location.href;
  if (process.env.REACT_ENV) {
    return process.env.REACT_ENV as ENVType;
  } else if (
    href.indexOf("localhost") !== -1 ||
    href.indexOf("127.0.0.1") !== -1
  ) {
    return "local";
  }
  if (href.indexOf("//dev.") !== -1) {
    return "dev";
  }
  if (href.indexOf("//test.") !== -1) {
    return "test";
  }
  return "prod";
};

export const ensureUndefinedString = (value: any, inputType: string) => {
  switch (inputType) {
    case "number":
      if (value === null || value === undefined || isNaN(value)) return "";
      break;
    default:
      if (value === null || value === undefined) return "";
      break;
  }
  return value;
};

export const enumsToOptions = (
  obj: any,
  prettyMethod?: any
): { value: string; label: string }[] => {
  return _.keys(obj).map((value) => ({
    value,
    label: prettyMethod ? prettyMethod(value) : prettyENUM(value),
  }));
};

export const insertTrademarks = (input: string) => {
  return input.replace(/\(R\)/g, "®");
};

export const mapEmailToLeoId = (email: string) => {
  const regex = /.+@leo-pharma.com/g;
  if (regex.test(email) === false) {
    console.warn(
      `string-utils.mapEmailToLeoId: Given email does not contain '@leo-pharma.com'. Given email: ${email}.`
    );
    return email;
  } else {
    const index = email.indexOf("@leo-pharma.com");
    const result = email.substr(0, index).toLowerCase();
    return result;
  }
};

/** Checks that a given url is valid */
export const emailIsValid = (email: string) => {
  const regex =
    // eslint-disable-next-line
    /(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  return regex.test(email);
};

export const hexToRGBA = (hex: string, alpha = 1): string => {
  var c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
      ","
    )},${alpha})`;
  }
  throw new Error("Bad Hex");
};

export const nullOrUndefined = (a: any) => {
  return a === undefined || a === null;
};

export const prettyFieldName = (filedName: string) => {
  if (!filedName) {
    return "";
  }
  return _.capitalize(filedName.split("_").join(" "));
};
