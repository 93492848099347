import { CardContent, Link, Typography } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { leoColorSunflower0 } from "LEOTheme/LEOColors";
import { leoSpacing } from "LEOTheme/LEOTheme";
import { AppMetadata } from "LEOTheme/utils/type-utils";
import React from "react";

interface LEONoAccessMessageProps {
  appMetadata: AppMetadata;
}

/**
 * @param props LEONoAccessMessageProps
 */
export const LEONoAccessMessage = (props: LEONoAccessMessageProps) => {
  /**
   * Render
   */
  return (
    <CardContent
      style={{
        padding: leoSpacing * 5,
      }}
    >
      <Typography variant="h1">
        <WarningIcon
          style={{
            fontSize: 28,
            color: leoColorSunflower0,
            position: "relative",
            top: 6,
            marginRight: leoSpacing,
          }}
        />
        {"Access restricted"}
      </Typography>
      <p>
        {"You do not have the necessary privileges to access LEO Pharma " +
          props.appMetadata.appTitle}
      </p>
      <p>
        {`To request access or for other inquiries please contact ${props.appMetadata.contactName} at `}
        <Link
          style={{ fontWeight: 900 }}
          href={`mailto:${props.appMetadata.contactEmail}?subject=${props.appMetadata.appTitle}`}
        >
          {props.appMetadata.contactEmail?.toLowerCase()}
        </Link>
      </p>
      <p style={{ marginTop: leoSpacing * 5 }}>{props.appMetadata.byline}</p>
    </CardContent>
  );
};
