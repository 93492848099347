import { RumInitConfiguration } from "@datadog/browser-rum";
import { getContextStateUtils } from "LEOTheme/utils/context-state-util";

export interface GlobalSpinnerItem {
  key: string;
  active: boolean;
}

interface LEOGlobalState {
  // List of global spinners
  globalSpinners: GlobalSpinnerItem[];

  // Datadog hook to rewrite / filter data being sent to Datadog
  beforeSend?: RumInitConfiguration["beforeSend"];
}

export const [LEOGlobalStateProvider, useLEOGlobalState] =
  getContextStateUtils<LEOGlobalState>(
    {
      globalSpinners: [],
      beforeSend: null,
    },
    "leo-blobal-state"
  );
