import React from "react";
import { LEOButton, LEOButtonProps } from "./LEOButton";

/**
 * Wrapper for LEOButton with default setting as Secondary
 *
 * @param props LEOButtonProps
 */
const LEOSecondaryButton = (props: LEOButtonProps) => {
  return (
    <LEOButton
      leoColor={"white"}
      variant={"contained"}
      color={"secondary"}
      {...props}
      style={{
        ...(props.variant === "text"
          ? {}
          : {
              border: "1px solid rgba(0, 0, 0, .12)",
              boxShadow: props.disabled
                ? undefined
                : "0px 2px 5px rgba(0, 0, 0, 0.08)",
            }),
        ...(props.style || {}),
      }}
    >
      {props.children}
    </LEOButton>
  );
};

export default LEOSecondaryButton;
