/**
 * Documentation:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/login-user.md
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/acquire-token.md
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/logout.md
 */
import {
  AuthenticationResult,
  Configuration,
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import { logError } from "LEOTheme/utils/error-utils";

/** Utils: */
export const current_url =
  window.location.protocol +
  "//" +
  window.location.hostname +
  ":" +
  window.location.port;

export const isIE = () => {
  const ua = window.navigator.userAgent;
  const msie = ua.indexOf("MSIE ") > -1;
  const msie11 = ua.indexOf("Trident/") > -1;
  return msie || msie11;
};

/** Config: */
export const msalConfig: Configuration = {
  auth: {
    authority: process.env.REACT_APP_AUTHORITY as string,
    clientId: process.env.REACT_APP_CLIENTID as string,
    redirectUri: current_url,
    postLogoutRedirectUri: current_url,
    navigateToLoginRequestUrl: true,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: isIE(),
  },
  system: {
    iframeHashTimeout: 10000,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

// Instanciate msal in a seperate version where redirectUri is set to static html file.
// This is done to mitigate this error: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/2263#issuecomment-691191179
export const msalSilentInstance = new PublicClientApplication({
  ...msalConfig,
  auth: { ...msalConfig.auth, redirectUri: current_url + "/auth.html" },
});

/** Requests: */
export const beApiAuthRequest = {
  scopes: [process.env.REACT_APP_API_SCOPE as string],
};

export const microsoftGraphAuthRequest = {
  scopes: [process.env.REACT_APP_MICROSOFT_GRAPH_API_SCOPE as string],
};

/**
 * Aquire BE API Token silently (requires account gained from redirect)
 */
export const aquireBeApiToken_Silent = async (): Promise<
  AuthenticationResult | { accessToken: string }
> => {
  // default token aquire
  const account = msalSilentInstance.getAllAccounts()[0];
  if (!account) {
    throw new InteractionRequiredAuthError("No account was detected");
  }

  try {
    return await msalSilentInstance.acquireTokenSilent({
      ...beApiAuthRequest,
      account: account,
    });
  } catch (error) {
    logError(error, "Error aquiring silent token", true, false);
    aquireBeApiToken_Redirect();
  }
};

/**
 * Aquire Microsoft Graph API Token silently (requires account gained from redirect)
 */
export const aquireMicrosoftGraphToken_Silent = async (): Promise<
  AuthenticationResult | { accessToken: string }
> => {
  // default token aquire
  const account = msalSilentInstance.getAllAccounts()[0];
  if (!account) {
    throw new InteractionRequiredAuthError("No account was detected");
  }
  try {
    return await msalSilentInstance.acquireTokenSilent({
      ...microsoftGraphAuthRequest,
      account: account,
    });
  } catch (error) {
    logError(error, "Error aquiring silent token", true, false);
    aquireMicrosoftGraphToken_Redirect();
  }
};

/**
 * Aquire BE API Token using redirect
 */
export const aquireBeApiToken_Redirect = async () => {
  return await msalInstance.acquireTokenRedirect(beApiAuthRequest);
};

/**
 * Aquire Microsoft Graph API token using redirect
 */
export const aquireMicrosoftGraphToken_Redirect = async () => {
  return await msalInstance.acquireTokenRedirect(microsoftGraphAuthRequest);
};

/**
 * Aquire BE API Token using popup
 */
export const aquireBeApiToken_Popup = async () => {
  return await msalInstance.acquireTokenPopup(beApiAuthRequest);
};

/**
 * Aquire Microsoft Graph API token using popup
 */
export const aquireMicrosoftGraphToken_Popup = async () => {
  return await msalInstance.acquireTokenPopup(microsoftGraphAuthRequest);
};

/**
 * Handle token redirect from either aquireMicrosoftGraphToken_Redirect() or aquireBEAPIToken_Redirect()
 *
 * @returns tokenResponse
 *
 * If the tokenResponse !== null, then you are coming back from a successful authentication redirect.
 *
 * If the tokenResponse === null, you are not coming back from an auth redirect.
 */
export const handleRedirectPromise = async () => {
  return await msalInstance.handleRedirectPromise();
};

/** Get CSRF token */
let cachedCSRFToken: string = null;
export const getCSRFToken = async (): Promise<string> => {
  if (cachedCSRFToken) return cachedCSRFToken;
  try {
    const csrfRequestResponse = await fetch(
      process.env.REACT_APP_API_REST + "csrf/",
      { credentials: "include" }
    );
    const csrfTokenJSON = await csrfRequestResponse.json();
    cachedCSRFToken = csrfTokenJSON.csrfToken;
    return csrfTokenJSON.csrfToken;
  } catch (error) {
    logError(error);
  }
};
