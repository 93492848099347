// Preview colors in
// https://www.figma.com/file/X7gYTKT19Ok4MCMiqQQwHD/Compeditor-Overview?node-id=963%3A1200

// UI
export const leoColorTextBlack: string = "#333333";
export const leoColorOrange: string = "#FF5000";
export const leoColorSand0: string = "#E9E5DE";
export const leoColorSand1: string = "#F3F1ED";
export const leoColorSand2: string = "#F9F7F4";
export const leoColorInputBorder = "#D2D9D6";
export const leoColorChipHover = "#E0F1F1";
export const leoColorChip = "#F2F6F6";
export const leoColorWhite = "#FFF";
export const leoBorderColor = "#D2D9D6";
export const leoBackdropColor = `rgba(32, 65, 49, .9)`;
export const leoHighlightColor = "rgba(255, 80, 0, 0.40)";
export const leoBrightError = "#f44336";

// Error
export const leoColorError0: string = "#BB4C4C";
export const leoColorError1: string = "#C77171";
export const leoColorError2: string = "#D99696";
export const leoColorError3: string = "#E6B9B9";
export const leoColorError4: string = "#F5E3E3";
export const leoColorError5: string = "#FCF7F7";

// Brand
export const leoColorDarkGreen0: string = "#204131";
export const leoColorDarkGreen1: string = "#4D675A";
export const leoColorDarkGreen2: string = "#798D83";
export const leoColorDarkGreen3: string = "#A6B3AD";
export const leoColorDarkGreen4: string = "#D2D9D6";
export const leoColorDarkGreen5: string = "#E8EBEA";

export const leoColorLightGreen0: string = "#68A17C";
export const leoColorLightGreen1: string = "#86B496";
export const leoColorLightGreen2: string = "#A4C7B0";
export const leoColorLightGreen3: string = "#C3D9CB";
export const leoColorLightGreen4: string = "#E1ECE5";
export const leoColorLightGreen5: string = "#F0F5F2";

// Chart
export const leoColorAubergine0: string = "#683664";
export const leoColorAubergine1: string = "#865E83";
export const leoColorAubergine2: string = "#A486A2";
export const leoColorAubergine3: string = "#C3AFC1";
export const leoColorAubergine4: string = "#EDE8EC";
export const leoColorAubergine5: string = "#FDF5FC";

export const leoColorSunflower0: string = "#EAAA00";
export const leoColorSunflower1: string = "#EEBB33";
export const leoColorSunflower2: string = "#F2CC66";
export const leoColorSunflower3: string = "#F7DD99";
export const leoColorSunflower4: string = "#FAF0D8";
export const leoColorSunflower5: string = "#FFFBF0";

export const leoColorPetrol0: string = "#006E96";
export const leoColorPetrol1: string = "#338BAB";
export const leoColorPetrol2: string = "#66A8C0";
export const leoColorPetrol3: string = "#99C5D5";
export const leoColorPetrol4: string = "#D8EBF2";
export const leoColorPetrol5: string = "#F3FAFD";

// Brand Unofficial
export const leoColorRust0: string = "#D85800";
export const leoColorRust1: string = "#DC7352";
export const leoColorRust2: string = "#DF9882";
export const leoColorRust3: string = "#E2B7AA";
export const leoColorRust4: string = "#F6ECE9";
export const leoColorRust5: string = "#FFFBF9";

export const leoColorCherry0: string = "#AF3552";
export const leoColorCherry1: string = "#C05B74";
export const leoColorCherry2: string = "#C87F90";
export const leoColorCherry3: string = "#DFAEBA";
export const leoColorCherry4: string = "#F5E6EA";
export const leoColorCherry5: string = "#FFF4F6";

export const leoColorLavender0: string = "#5B5E9D";
export const leoColorLavender1: string = "#7B7DB0";
export const leoColorLavender2: string = "#9B9CC0";
export const leoColorLavender3: string = "#B9BAD2";
export const leoColorLavender4: string = "#D9DAEC";
export const leoColorLavender5: string = "#F5F6FF";

export const leoColorLagune0: string = "#3E979C";
export const leoColorLagune1: string = "#5CACB0";
export const leoColorLagune2: string = "#81C4C8";
export const leoColorLagune3: string = "#BEE4E7";
export const leoColorLagune4: string = "#D4F3F4";
export const leoColorLagune5: string = "#EBF9F9";

export const leoColorPeacock0: string = "#53B692";
export const leoColorPeacock1: string = "#7BCEB0";
export const leoColorPeacock2: string = "#94DCC2";
export const leoColorPeacock3: string = "#C5E3D8";
export const leoColorPeacock4: string = "#E9F5F1";
export const leoColorPeacock5: string = "#EEFEF8";

// types
export type LEOColor =
  | "darkGreen"
  | "lightGreen"
  | "white"
  | "aubergine"
  | "sunflower"
  | "petrol"
  | "rust"
  | "cherry"
  | "lavender"
  | "lagune"
  | "peacock"
  | "error";

/**
 * LEOColor sets
 *
 * Color sets are combination of colors including css properties: color, backgroundColor and hoverColor
 * Sets can be used things like buttons, tags eg.
 */

export interface LEOColorSet {
  /** The LEOColor name  */
  leoColor: LEOColor;

  /** Base color */
  base: string;

  /** Variations */
  variation1: string;
  variation2: string;
  variation3: string;
  variation4: string;
  variation5: string;

  /** Background color of the set */
  backgroundColor: string;

  /** Color of set */
  color: string;

  /** Background color for hover */
  hoverBackgroundColor: string;
}

// all available color sets
export const leoColorSets: LEOColorSet[] = [
  {
    leoColor: "white",
    base: leoColorWhite,
    variation1: leoColorWhite,
    variation2: leoColorWhite,
    variation3: leoColorWhite,
    variation4: leoColorWhite,
    variation5: leoColorWhite,
    backgroundColor: leoColorWhite,
    hoverBackgroundColor: leoColorLightGreen5,
    color: leoColorTextBlack,
  },
  {
    leoColor: "darkGreen",
    base: leoColorDarkGreen0,
    variation1: leoColorDarkGreen1,
    variation2: leoColorDarkGreen2,
    variation3: leoColorDarkGreen3,
    variation4: leoColorDarkGreen4,
    variation5: leoColorDarkGreen5,
    backgroundColor: leoColorDarkGreen0,
    hoverBackgroundColor: leoColorDarkGreen1,
    color: leoColorWhite,
  },
  {
    leoColor: "lightGreen",
    base: leoColorLightGreen0,
    variation1: leoColorLightGreen1,
    variation2: leoColorLightGreen2,
    variation3: leoColorLightGreen3,
    variation4: leoColorLightGreen4,
    variation5: leoColorLightGreen5,
    backgroundColor: leoColorLightGreen0,
    hoverBackgroundColor: leoColorLightGreen1,
    color: leoColorWhite,
  },
  {
    leoColor: "aubergine",
    base: leoColorAubergine0,
    variation1: leoColorAubergine1,
    variation2: leoColorAubergine2,
    variation3: leoColorAubergine3,
    variation4: leoColorAubergine4,
    variation5: leoColorAubergine5,
    backgroundColor: leoColorAubergine0,
    hoverBackgroundColor: leoColorAubergine1,
    color: leoColorWhite,
  },
  {
    leoColor: "sunflower",
    base: leoColorSunflower0,
    variation1: leoColorSunflower1,
    variation2: leoColorSunflower2,
    variation3: leoColorSunflower3,
    variation4: leoColorSunflower4,
    variation5: leoColorSunflower5,
    backgroundColor: leoColorSunflower0,
    hoverBackgroundColor: leoColorSunflower1,
    color: leoColorWhite,
  },
  {
    leoColor: "petrol",
    base: leoColorPetrol0,
    variation1: leoColorPetrol1,
    variation2: leoColorPetrol2,
    variation3: leoColorPetrol3,
    variation4: leoColorPetrol4,
    variation5: leoColorPetrol5,
    backgroundColor: leoColorPetrol0,
    hoverBackgroundColor: leoColorPetrol1,
    color: leoColorWhite,
  },
  {
    leoColor: "rust",
    base: leoColorRust0,
    variation1: leoColorRust1,
    variation2: leoColorRust2,
    variation3: leoColorRust3,
    variation4: leoColorRust4,
    variation5: leoColorRust5,
    backgroundColor: leoColorRust0,
    hoverBackgroundColor: leoColorRust1,
    color: leoColorWhite,
  },
  {
    leoColor: "cherry",
    base: leoColorCherry0,
    variation1: leoColorCherry1,
    variation2: leoColorCherry2,
    variation3: leoColorCherry3,
    variation4: leoColorCherry4,
    variation5: leoColorCherry5,
    backgroundColor: leoColorCherry0,
    hoverBackgroundColor: leoColorCherry1,
    color: leoColorWhite,
  },
  {
    leoColor: "lavender",
    base: leoColorLavender0,
    variation1: leoColorLavender1,
    variation2: leoColorLavender2,
    variation3: leoColorLavender3,
    variation4: leoColorLavender4,
    variation5: leoColorLavender5,
    backgroundColor: leoColorLavender0,
    hoverBackgroundColor: leoColorLavender1,
    color: leoColorWhite,
  },
  {
    leoColor: "lagune",
    base: leoColorLagune0,
    variation1: leoColorLagune1,
    variation2: leoColorLagune2,
    variation3: leoColorLagune3,
    variation4: leoColorLagune4,
    variation5: leoColorLagune5,
    backgroundColor: leoColorLagune0,
    hoverBackgroundColor: leoColorLagune1,
    color: leoColorWhite,
  },
  {
    leoColor: "peacock",
    base: leoColorPeacock0,
    variation1: leoColorPeacock1,
    variation2: leoColorPeacock2,
    variation3: leoColorPeacock3,
    variation4: leoColorPeacock4,
    variation5: leoColorPeacock5,
    backgroundColor: leoColorPeacock0,
    hoverBackgroundColor: leoColorPeacock1,
    color: leoColorWhite,
  },
  {
    leoColor: "error",
    base: leoColorError0,
    variation1: leoColorError1,
    variation2: leoColorError2,
    variation3: leoColorError3,
    variation4: leoColorError4,
    variation5: leoColorError5,
    backgroundColor: leoColorError0,
    hoverBackgroundColor: leoColorError1,
    color: leoColorWhite,
  },
];

export const getSet = (leoColor: LEOColor): LEOColorSet => {
  return leoColorSets.find((lcs) => lcs.leoColor === leoColor);
};
