export const ensure = <T>(
  argument: T | undefined | null,
  message: string = "This value was promised to be there."
): T => {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }
  return argument;
};

interface IDictionary<TValue> {
  [key: string]: TValue;
}

export const asDict = (o: any): IDictionary<any> => {
  return o;
};

export interface LEOThemeConfig {
  disableProfilePictures: false;

  /** query or mutation files eg. [ALL_USEROBJECTPERMISSION_SLIM, ALL_USEROBJECTPERMISSION] */
  disableGQLFiles?: string[];

  /** query or mutation files eg. [ALL_USEROBJECTPERMISSION_SLIM, ALL_USEROBJECTPERMISSION] */
  disableThemeGQLFiles?: string[];

  /** app metadata */
  appMetaData: AppMetadata;

  /** mask DD actions */
  maskDatadogActions?: boolean;
}

export interface AppMetadata {
  appTitle: string;
  contactName: string;
  contactEmail: string;
  byline: string;
  feedbackChannel?: {
    teamsChannelURL: string;
    message?: string | null;
  };
}
