import { Chip, Theme, useMediaQuery } from "@material-ui/core";
import { leoThemeConfig } from "leo-theme-config";
import {
  leoColorCherry0,
  leoColorPeacock0,
  leoColorPetrol0,
  leoColorWhite,
} from "LEOTheme/LEOColors";
import { leoSpacing } from "LEOTheme/LEOTheme";
import { aquireBeApiToken_Silent } from "LEOTheme/utils/auth-utils";
import { getENV } from "LEOTheme/utils/string-utils";
import { getTypeGenCommand } from "LEOTheme/utils/typegen";
import React from "react";
import { ReactComponent as Logo } from "../images/leo-logo.svg";
import { LEONavLink } from "./LEONavLink";

export interface LEOAppIdentProps {
  /** Top of left header - Defaults to 'LEO Pharma' */
  titleTop?: string;

  /** Bottom of left header - Should be the title of the app*/
  appTitle?: string;

  /** Logo of bar. Defaults to LEO Logo */
  logo?: any;

  /** inverts the colors */
  inverted?: boolean;
}

/**
 * Leo logo and app name combination used in LEOAuthProvider and LEOAppBar
 *
 * @param props LEOAppIdentProps
 */
export const LEOAppIdent = (props: LEOAppIdentProps) => {
  const { titleTop = "LEO Pharma", inverted = false } = props;

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xs")
  );

  // get env to display badge
  const env = getENV();

  /**
   * Render
   */
  return (
    <div style={{ display: "flex" }}>
      <LEONavLink
        style={{
          display: "flex",
        }}
        to="/"
      >
        <Logo
          style={{
            marginRight: (isMobile ? 1 : 2) * leoSpacing,
          }}
          fill={inverted ? leoColorWhite : "black"}
        />
        <div>
          <strong
            style={{
              display: "block",
              fontSize: 11,
              color: inverted && leoColorWhite,
            }}
          >
            {titleTop}
          </strong>
          <span
            style={{
              display: "flex",
              fontSize: 15,
              color: inverted && leoColorWhite,
              whiteSpace: !isMobile ? "nowrap" : "unset",
            }}
          >
            {props.appTitle}
            {env !== "prod" && (
              <Chip
                label={env.toUpperCase()}
                style={{
                  top: -leoSpacing * 0.75,
                  position: "relative",
                  marginLeft: leoSpacing * 2,
                  color: leoColorWhite,
                  fontWeight: 900,
                  backgroundColor: (() => {
                    switch (env) {
                      case "local":
                        return leoColorCherry0;
                      case "dev":
                        return leoColorPetrol0;
                      case "test":
                        return leoColorPeacock0;
                    }
                  })(),
                }}
                onClick={async () => {
                  if (env === "local") {
                    const token = await aquireBeApiToken_Silent();
                    const input = document.body.appendChild(
                      document.createElement("input")
                    );

                    input.value = getTypeGenCommand(
                      process.env.REACT_APP_API_ENDPOINT,
                      token.accessToken,
                      leoThemeConfig.disableGQLFiles || [],
                      leoThemeConfig.disableThemeGQLFiles || []
                    );
                    input.focus();
                    input.select();
                    document.execCommand("copy");
                    input.parentNode.removeChild(input);
                    console.log("Full npx script copied to clipboard");
                  }
                }}
              />
            )}
          </span>
        </div>
      </LEONavLink>
    </div>
  );
};
