import { createMuiTheme, makeStyles } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import {
  leoColorError0,
  leoColorError2,
  leoColorLightGreen2,
} from "LEOTheme/LEOColors";
import {
  leoBorderColor,
  leoColorChip,
  leoColorChipHover,
  leoColorLightGreen0,
  leoColorLightGreen1,
  leoColorLightGreen4,
  leoColorLightGreen5,
  leoColorPetrol4,
  leoColorTextBlack,
  leoColorWhite,
} from "./LEOColors";
import { hexToRGBA } from "./utils/string-utils";

/**
 * Spacing
 */
export const leoSpacing = 6;
export const leoGridSpacing = 4;

/**
 * Default theme
 */
const defaultTheme = createMuiTheme({
  spacing: leoSpacing,
});

/**
 * Sizes
 */
export const leoLayoutInputHeight = 32;
export const leoLayoutAppBarHeight = 60;
export const leoLayoutNavBarWidth = 80;
export const leoLayoutSecondaryNavBarWidth = 200;
export const leoLayoutContainerPadding = defaultTheme.spacing(4);
export const leoLayoutContainerPaddingMobile = defaultTheme.spacing(2);
export const leoLayoutChipSpacing = defaultTheme.spacing(0.25);

/**
 * Shadows
 */
export const leoShadowUIRaised = "0px 2px 6px rgba(0, 0, 0, 0.125)";
export const leoShadowUIRaisedLarge = "0px 3px 10px rgba(0, 0, 0, 0.25)";
export const leoShadowUIInset = "inset 0px 2px 4px rgba(0, 0, 0, 0.12)";

/**
 * Shape
 */
export const leoBorderRadiusMain = 3;

/**
 * Utils
 */

/**
 * Get border object
 *
 * @param color Border color
 * @param width Border width
 *
 * @returns CSSProperties describing the border
 */

export const leoGetBorder = (
  color: string = leoBorderColor,
  width: number = 1
): CSSProperties => {
  return {
    borderColor: color,
    borderWidth: width,
    borderStyle: "solid",
  };
};

/**
 * Get border string
 *
 * @param color
 * @param width
 *
 * @returns border string
 */
export const leoGetBorderString = (
  color: string = leoBorderColor,
  width: number = 1
): string => {
  return `${width}px solid ${color}`;
};

/**
 * Style bundles
 */

export const leoStyleFlexRowCenter: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
};

export const leoStyleFlexFullHeight = {
  flex: 1,
  display: "flex",
  flexDirection: "column" as any,
};

/**
 * Utility classes
 */

export const useUtilityClasses = makeStyles(() => {
  return {
    noSelect: {
      "-webkit-touch-callout": "none",
      "-webkit-user-select": "none",
      "-khtml-user-select": "none",
      "-moz-user-select": "none",
      "-ms-user-select": "none",
      "user-select": "none",
    },
  };
});

/**
 * Material UI Theme definition
 */

export const leoTheme = createMuiTheme({
  spacing: leoSpacing,
  typography: {
    h1: {
      marginBottom: ".5em",
      marginTop: ".25em",
      fontSize: 21,
      fontWeight: 400,
    },
    h2: {
      marginBottom: ".5em",
      marginTop: ".25em",
      fontSize: 18,
      fontWeight: 400,
    },
    h3: {
      marginBottom: ".5em",
      marginTop: ".25em",
      fontSize: 16,
      fontWeight: 400,
    },
    h4: {
      marginBottom: ".5em",
      marginTop: ".25em",
      fontSize: 14,
      fontWeight: 600,
    },
    h5: {
      marginBottom: ".5em",
      marginTop: ".25em",
      fontSize: 12,
      fontWeight: 900,
    },
    h6: {
      marginBottom: ".5em",
      marginTop: ".25em",
      fontSize: 11,
      fontWeight: 900,
    },
    body1: {
      fontSize: 13,
    },
    button: {
      fontWeight: 900,
      fontSize: 12,
      textTransform: "none",
    },
    fontFamily: [
      "GillroyOffice",
      "Arial",
      "-apple-system",
      '"Helvetica Neue"',
      "sans-serif",
    ].join(","),
  } as any,
  props: {
    MuiGrid: {
      spacing: 3,
    },
    MuiChip: {
      // deleteIcon: React.createElement(CloseIcon)
    },
  },
  /*
  shadows: defaultTheme.shadows.map((s, i) => {
    if (i === 0) return 'none';
    const width = i * (150 / defaultTheme.shadows.length) + 5;
    return `0px 4px ${width}px rgba(0, 0, 0, 0.15)`
  } ) as Shadows,
  */
  overrides: {
    MuiTable: {
      root: {},
    },

    MuiTableHead: {
      root: {},
    },

    MuiTableRow: {
      root: {
        "&.MuiTableRow-hover, &.MuiTableRow-hover td:first-child": {
          transition: "background-color .1s ease-in-out",
        },
        "&.MuiTableRow-hover:hover": {
          cursor: "pointer",
          backgroundColor: leoColorLightGreen5 + "!important",
        },
        "&.MuiTableRow-hover:hover td:first-child": {
          cursor: "pointer",
          backgroundColor: leoColorLightGreen5 + "!important",
        },
      },
    },

    MuiBadge: {
      colorPrimary: {
        backgroundColor: leoColorLightGreen0,
        ...leoGetBorder(leoColorLightGreen2),
      },
      colorError: {
        backgroundColor: leoColorError0,
        ...leoGetBorder(leoColorError2),
      },
      badge: {
        fontWeight: 900,
        boxShadow: leoShadowUIRaised,
      },
    },

    MuiChip: {
      sizeSmall: {
        fontSize: 9,
        height: 17,
      },
      labelSmall: {
        paddingLeft: leoSpacing,
        paddingRight: leoSpacing,
      },
      clickable: {
        "&:hover": {
          backgroundColor: leoColorChipHover,
        },
        "&:focus": {
          backgroundColor: leoColorChipHover,
        },
        "&:active": {
          ...leoGetBorder(defaultTheme.palette.divider),
        },
      },
      deletable: {
        "&:hover": {
          backgroundColor: leoColorChipHover,
        },
        "&:active": {
          ...leoGetBorder(defaultTheme.palette.divider),
        },
      },
      root: {
        fontSize: 11,
        fontWeight: 600,
        height: 22,
        position: "relative",
        backgroundColor: leoColorChip,
        boxShadow: leoShadowUIRaised,
        ...leoGetBorder(defaultTheme.palette.divider),
        borderRadius: 3,
        margin: leoLayoutChipSpacing,
      },
      deleteIcon: {
        height: 14,
        width: 14,
        LeoColor: "#333333",
      },
    },

    // chekcbox
    MuiCheckbox: {
      root: {
        marginRight: -leoSpacing * 0.75,
      },
    },

    // Link
    MuiLink: {
      root: {
        fontWeight: 600,
      },
    },

    // toolbar
    MuiToolbar: {
      gutters: {
        [defaultTheme.breakpoints.up("sm")]: {
          paddingLeft: defaultTheme.spacing(4),
          paddingRight: defaultTheme.spacing(4),
        },
      },
    },

    MuiInputLabel: {
      shrink: {
        whiteSpace: "nowrap",
      },
    },

    // form label
    MuiFormLabel: {
      root: {
        fontWeight: 600,
        fontSize: 16,
        "& + .MuiInput-formControl": {
          marginTop: defaultTheme.spacing(2),
        },
      },
    },

    // list sub header
    MuiListSubheader: {
      root: {
        fontWeight: 900,
        lineHeight: leoSpacing * 6 + "px",
        marginTop: leoSpacing * 2,
        color: leoColorTextBlack,
      },
    },

    // list item
    MuiListItem: {
      button: {
        "&:hover": {
          backgroundColor: leoColorLightGreen5,
        },
      },
      root: {
        "& h5": {
          margin: 0,
          paddingTop: leoSpacing * 0.5,
          whiteSpace: "nowrap",
        },
        borderTop: leoGetBorderString(),
        "&:first-child": {
          borderTop: 0,
        },
        fontSize: 12,
        "&$selected": {
          backgroundColor: leoColorLightGreen4,
        },
        "&$selected:hover": {
          backgroundColor: leoColorLightGreen4,
        },
      },
    },

    MuiTableCell: {
      head: {
        fontWeight: 900,
      },
      root: {
        position: "relative",
        padding: defaultTheme.spacing(1),
        paddingTop: defaultTheme.spacing(1),
        paddingBottom: defaultTheme.spacing(1),
        verticalAlign: "top",
        "&:first-child": {
          paddingLeft: 0,
        },
        "&:last-child": {
          paddingRight: 0,
        },
        '& [class*="MUIDataTableHeadCell-sortAction"]': {
          alignItems: "center",
        },
      },
    },

    MuiInputBase: {
      input: {
        minHeight: 18,
      },
    },

    // input base
    /*
    MuiInputBase: {
      input: {
        fontSize: 14,
        minHeight: inputHeight,
        boxSizing: 'border-box',
        "&:focus": {
          ...border(colorLightGreen)
        },
        paddingLeft: defaultTheme.spacing(1),
        paddingRight: defaultTheme.spacing(1),
        boxShadow: shadowUIInset,
        borderRadius: borderRadiusMain,
        ...border(colorInputBorder)
      }
    },
    */

    // Input under line
    /*
     */
    MuiInput: {
      underline: {
        "&:before": {
          borderBottomColor: leoBorderColor,
        },
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: leoGetBorderString("#C3D9CB", 2),
        },
      },
    },

    // input select
    MuiSelect: {
      root: {},
      select: {
        "&, &:focus": {
          backgroundColor: "transparent",
        },
      },
    },
    /*
     */

    // tabs
    MuiTabs: {
      flexContainer: {},
      indicator: {
        display: "none",
      },
      root: {
        minHeight: 0,
        minWidth: "none",
        paddingRight: leoLayoutContainerPadding,
        paddingLeft: leoLayoutContainerPadding,
      },
    },

    // Icon Button
    MuiIconButton: {
      root: {
        LeoColor: leoColorTextBlack,
      },
    },

    // chip input
    MuiFormControl: {
      root: {
        "&.MuiChipField > div > div": {
          marginTop: 13,
          minHeight: "auto",
          "&:before": {
            borderBottomColor: leoBorderColor,
          },
        },
        "&.MuiChipField .Mui-focused + div > div:after": {
          borderBottomColor: leoColorLightGreen0,
        },
        "&.MuiChipField:hover > div > div:before": {
          borderBottomColor: leoGetBorderString("#C3D9CB", 2) + " !important",
        },
        "&.MuiChipField:hover div > div:before": {
          borderBottom: leoGetBorderString("#C3D9CB", 2) + " !important",
        },
        // leo theme light
        "&.LEOTheme-light .MuiInput-underline::before": {
          borderBottomColor: "rgba(255, 255, 255, .25)",
        },
        "&.LEOTheme-light .MuiInputBase-input": {
          color: leoColorWhite,
        },
      },
    },

    MuiTab: {
      textColorInherit: {
        LeoColor: leoColorTextBlack,
        opacity: 1,
      },
      wrapper: {
        padding: 0,
      },
      root: {
        [defaultTheme.breakpoints.up("sm")]: {
          minWidth: 50,
          fontSize: "unset",
        },
        "&.Mui-selected": {
          backgroundColor: leoColorWhite,
        },
        fontSize: "unset",
        fontWeight: 600,
        minHeight: 0,
        marginRight: 1,
        borderTopLeftRadius: leoBorderRadiusMain,
        borderTopRightRadius: leoBorderRadiusMain,
        paddingTop: 9,
        paddingBottom: 9,
        paddingLeft: 19,
        paddingRight: 19,
        backgroundColor: hexToRGBA(leoColorWhite, 0.6),
        color: leoColorTextBlack,
      },
    },

    // card
    MuiCard: {
      root: {
        "& + .MuiCard-root": {
          marginTop: defaultTheme.spacing(2),
        },
      },
    },

    MuiCardActions: {
      root: {
        padding: defaultTheme.spacing(2),
        paddingTop: 0,
      },
    },

    // Backdrop
    MuiBackdrop: {
      root: {
        backgroundColor: `rgba(32, 65, 49, .35)`,
      },
    },

    // paper

    MuiPaper: {
      elevation24: {
        boxShadow: "0px 4px 100px rgba(0, 0, 0, 0.65)",
      },
    },

    // Dialog
    MuiDialog: {
      paper: {
        minWidth: 300,
      },
    },

    MuiDialogContent: {
      root: {
        paddingTop: defaultTheme.spacing(1),
        paddingBottom: defaultTheme.spacing(1),
        "&:last-child": {
          paddingBottom: defaultTheme.spacing(3),
        },
      },
    },

    MuiTooltip: {
      tooltip: {
        fontSize: "13px",
      },
    },

    // button
    MuiButton: {
      contained: {
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
        "&:hover": {
          boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.3)",
        },
      },

      containedSecondary: {
        boxShadow: "0px 2px 7px rgba(0, 0, 0, 0.1)",
        "&:hover": {
          boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.2)",
        },
      },
      startIcon: {
        marginRight: defaultTheme.spacing(0.5),
      },
      endIcon: {
        "&.MuiButton-iconSizeSmall": {
          marginLeft: 0,
        },
        "&.MuiButton-iconSizeSmall svg": {
          marginTop: -1,
          marginBottom: -1,
        },
      },
      label: {
        textDecoration: "none",
      },
      containedSizeSmall: {
        padding: "3px 10px",
        fontSize: "11px",
      },
      sizeSmall: {
        padding: "3px 10px",
        fontSize: "11px",
      },
      root: {
        fontSize: "13px",
        lineHeight: 1.45,
        "& + .MuiButton-root": {
          marginLeft: defaultTheme.spacing(1),
        },
      },
    },
  },
  shape: {
    borderRadius: leoBorderRadiusMain,
  },
  palette: {
    text: {
      primary: leoColorTextBlack,
    },
    primary: {
      main: leoColorLightGreen0,
    },
    secondary: {
      main: leoColorLightGreen1,
      dark: leoColorPetrol4,
    },
  },
});
