import { CardContent, Link, makeStyles, Typography } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { leoColorSunflower0 } from "LEOTheme/LEOColors";
import { leoSpacing } from "LEOTheme/LEOTheme";
import { AppMetadata } from "LEOTheme/utils/type-utils";
import { LEOStatusMessageContext } from "LEOTheme/utils/use-status-message";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { LEOAbout } from "./LEOAbout";

export interface LEOStatusCheckProviderProps extends PropsWithChildren<any> {
  /** Props for LEOAppIdent - used for displaying loader */
  appMetadata: AppMetadata;
}

interface OnlineStatus {
  type: "online" | "maintenance" | "offline";
  message?: string;
}

export interface MessageStatus {
  type: "no-message" | "info" | "warning";
  message?: string;
}

interface Env {
  backendUrl: string;
  frontendUrl: string;
  label: string;
  link: string;
  logUrl: string;
  long: string;
  statusUrl: string;
  type: string;
  onlineStatus?: OnlineStatus;
  messageStatus: MessageStatus;
}

const useStyles = makeStyles(() => ({
  centerContainer: {
    display: "flex",
    position: "fixed",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
}));

/**
 *
 *
 * @param props LEOStatusCheckProviderProps
 */
export const LEOStatusCheckProvider = (props: LEOStatusCheckProviderProps) => {
  const classes = useStyles();

  /**
   * Local state
   */

  const [onlineStatus, setOnlineStatus] = useState<OnlineStatus>(null);
  const [messageStatus, setMessageStatus] = useState<MessageStatus>(null);

  /**
   * Effects and utils
   */

  const updateStatus = async () => {
    try {
      const statusResponse = await fetchStatus();
      setOnlineStatus(statusResponse.onlineStatus);
      setMessageStatus(statusResponse.messageStatus);
    } catch (error) {
      // error fetching status - ignore to enable application rendering
      setOnlineStatus({
        type: "online",
      });
    }
  };

  useEffect(() => {
    // trigger first status update
    updateStatus();

    // initiate polling
    setInterval(() => updateStatus(), 1000 * 60 * 60);
  }, []);

  /**
   * Render
   */

  // wait for first status
  if (!onlineStatus)
    return (
      <div className={classes.centerContainer}>
        <LEOAbout useCase={"loading"} appMetadata={props.appMetadata} />
      </div>
    );

  // switch based on onlinestatus
  switch (onlineStatus.type) {
    case "online":
      return (
        <LEOStatusMessageContext.Provider value={messageStatus}>
          {props.children}
        </LEOStatusMessageContext.Provider>
      );
    case "offline":
    case "maintenance":
      return (
        <div className={classes.centerContainer}>
          <LEOAbout
            useCase={"about"}
            alternateContent={
              <CardContent
                style={{
                  padding: leoSpacing * 5,
                  maxWidth: 600,
                }}
              >
                <Typography variant="h1">
                  <WarningIcon
                    style={{
                      fontSize: 28,
                      color: leoColorSunflower0,
                      position: "relative",
                      top: 6,
                      marginRight: leoSpacing,
                    }}
                  />
                  {props.appMetadata.appTitle + " not available"}
                </Typography>
                <p>{onlineStatus.message}</p>
                <p>
                  {`If you have any questions please contact ${props.appMetadata.contactName} at `}
                  <Typography variant={"body1"} style={{ display: "inline" }}>
                    <Link
                      style={{ fontWeight: 900 }}
                      href={`mailto:${props.appMetadata.contactEmail}?subject=${props.appMetadata.appTitle}`}
                    >
                      {props.appMetadata.contactEmail?.toLowerCase()}
                    </Link>
                  </Typography>
                </p>
              </CardContent>
            }
            appMetadata={props.appMetadata}
          />
        </div>
      );
  }
};

export const fetchStatus = async (): Promise<{
  messageStatus: MessageStatus;
  onlineStatus: OnlineStatus;
}> => {
  try {
    /**
     * Status from dashboard projects.json
     */

    // load project json
    const dashboardProjects = await (
      await fetch("https://dashboard.leopharma-app.com/projects.json", {
        mode: "cors",
      })
    ).json();

    // parse backend domain
    const backendDomain = (() => {
      const noProtocol =
        process.env.REACT_APP_API_ENDPOINT.split("https://")[1];
      return noProtocol.split("/api")[0];
    })();

    // find env
    let env: Env = dashboardProjects
      .flatMap((dp) => dp.envs)
      .find((env) => env.backendUrl.includes(backendDomain));

    /**
     * status from endpoint defined in project json
     */

    if (env && env.statusUrl) {
      const apiStatus = await fetch(env.statusUrl);
      // return offline status if status is not 200
      if (apiStatus.status !== 200) {
        return {
          messageStatus: null,
          onlineStatus: {
            type: "offline",
            message:
              "We are currently experiecing problems with the service. We are working on bringing the service online ASAP",
          },
        };
      }
    }

    /**
     * do return
     */

    // return status
    return {
      messageStatus: env.messageStatus || { type: "no-message" },
      onlineStatus: env.onlineStatus || { type: "online" },
    };
  } catch (error) {
    return {
      messageStatus: { type: "no-message" },
      onlineStatus: { type: "online" },
    };
  }
};
