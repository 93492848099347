import React, {
  createContext,
  useContext,
  useReducer,
  FunctionComponent,
} from "react";

/**
 * Initialize state to be shared across application. Internally using the React Context API, and supports storing state in local storage.
 *
 * Pass interface to enable types in useGlobalStateHook eg. getGlobalStateUtils\<IGlobalState\>(...)
 *
 * @Returns Array containing Provider component class and useGlobalState hook
 * @param initialState the initial applicatoin states
 * @param localStorageKey the key used for local storage when persisting values
 */

export function getContextStateUtils<T>(
  initialState: T,
  localStorageKey: string
): [
    FunctionComponent<{ children: React.ReactNode }>,
    () => [T, (update: Partial<T>, persist?: boolean) => void]
  ] {
  // get local storage or set empty object
  const ls =
    localStorage.getItem(localStorageKey) !== null
      ? JSON.parse(localStorage.getItem(localStorageKey) || "")
      : {};

  // create context
  const StateContext = createContext(undefined);

  const GlobalStateProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(
      (state, update) => {
        // simple reducer function that applies the properties supplied in the update on top of current state
        return { ...state, ...update };
      },
      // initial state for reducer merges initial state supplied in getContextStateUtils() with loaded state from localStorage.
      { ...initialState, ...ls }
    );
    return (
      <StateContext.Provider
        value={
          [
            state,
            (update: T, persist: boolean) => {
              dispatch(update);

              // if persist value save to local storage
              if (persist) {
                const ls =
                  localStorage.getItem(localStorageKey) !== null
                    ? JSON.parse(localStorage.getItem(localStorageKey) || "")
                    : {};
                localStorage.setItem(
                  localStorageKey,
                  JSON.stringify({
                    ...ls,
                    ...update,
                  })
                );
              }
            },
          ] as any
        }
      >
        {children}
      </StateContext.Provider>
    );
  };

  return [
    GlobalStateProvider,
    (): [T, (update: Partial<T>, persist?: boolean) => void] =>
      useContext(StateContext) as any,
  ];
}
