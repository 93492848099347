import {
  datadogRum,
  DefaultPrivacyLevel,
  RumInitConfiguration
} from "@datadog/browser-rum";
import { leoThemeConfig } from "leo-theme-config";
import { useLEOGlobalState } from "LEOTheme/utils/leo-global-state";
import { stringIsTrue } from "LEOTheme/utils/string-utils";
import { PropsWithChildren, useEffect, useRef, useState } from "react";

interface CustomAction {
  name: string;
  payload?: any;
}

interface UseDatadogHookReturn {
  trackAction: (a: CustomAction) => void, 
  setGlobalContextProperty: (key: string, value: any) => void 
}

export const useDatadog = (): UseDatadogHookReturn => {
  return {
    trackAction: (a) => {
      datadogRum.addAction(a.name, a.payload);
    },
    setGlobalContextProperty: (key, value) => {
      datadogRum.setGlobalContextProperty(key, value);
    }
  };
};

export interface LEODatadogProviderProps extends PropsWithChildren<any> {}

/**
 * Wrapper for enabling logging to datadog
 *
 * @param props LEODatadogProviderProps
 */
export const LEODatadogProvider = (props: LEODatadogProviderProps) => {
  const [initDone, setInitDone] = useState(false);

  /**
   * Listen for beforeSend hook to be set by underlying appliaction
   */

  // Ref is used to handle function scope challenge
  const beforeSend = useRef<RumInitConfiguration["beforeSend"] | null>(null);
  const [leoGlobalState] = useLEOGlobalState();

  // Listen for hook being set and apply to ref used by the init method
  useEffect(() => {
    if (leoGlobalState.beforeSend) {
      beforeSend.current = leoGlobalState.beforeSend;
    }
  }, [leoGlobalState.beforeSend]);

  /**
   * Main effect that inits the Datadog api
   */
  useEffect(
    () => {
      // Do init if enabled and not already initiated
      if (stringIsTrue(process.env.REACT_APP_DATADOG_ENABLED) && !initDone) {
        /**
         * Init Datadog FE tracking
         *
         * See https://docs.datadoghq.com/real_user_monitoring/browser/#configuration for documentation of ENV's
         */

        datadogRum.init({
          beforeSend: (event, context) => {
            // pre-flight global masking of action elment names - done to make sure no unwanted info leaks to DD
            // https://docs.datadoghq.com/real_user_monitoring/browser/modifying_data_and_context/?tab=npm
            if (leoThemeConfig.maskDatadogActions) {
              if (event.action && event.action["target"] && event.action['type'] !== 'custom') {
                try {
                  // set new action target name
                  event.action["target"].name = event.action["target"].name
                    .split("") // split to single chararter string
                    .map((c) => (c === " " ? " " : "x")) // override all non-space charaters with x
                    .join(""); // rejoin name to single string
                } catch (error) {
                  // if something goes wrong during masking return false to omit tracking of the event
                  return false;
                }
              }
            }

            // check if hook for custom manipulation has been implemented
            if (beforeSend.current) {
              beforeSend.current(event, context);
            }
          },
          applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
          clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
          site: process.env.REACT_APP_DATADOG_SITE,
          service: process.env.REACT_APP_DATADOG_SERVICE,
          env: process.env.REACT_APP_DATADOG_ENV,
          version: process.env.REACT_APP_DATADOG_VERSION,
          sampleRate: 100,
          trackInteractions: stringIsTrue(
            process.env.REACT_APP_DATADOG_TRACK_INTERACTIONS
          ),
          defaultPrivacyLevel: process.env
            .REACT_APP_DATADOG_TRACK_DEFAULT_PRIVACY_LEVEL as DefaultPrivacyLevel,
        });

        // set init done
        setInitDone(true);

        // start recording if enabled
        if (
          stringIsTrue(process.env.REACT_APP_DATADOG_START_REPLAY_RECORDING)
        ) {
          datadogRum.startSessionReplayRecording();
        }
      }
    },

    // eslint-disable-next-line
    []
  );

  /**
   * Render
   */
  return props.children;
};
