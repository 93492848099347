import { gql } from "@apollo/client";
export const ME = gql`
  query Me {
    me {
      id
      lastLogin
      isSuperuser
      username
      firstName
      lastName
      email
      isStaff
      isActive
      dateJoined
    }
  }
`;
